import { InjectionToken } from '@angular/core';
import * as fromAlarms from '@app/home/features/alarms/store/alarms.reducer';
import * as fromAnalysis from '@app/home/features/analysis/store/analysis.reducer';
import * as fromDashboard from '@app/home/features/dashboard/store/dashboard.reducer';
import * as fromError from '@app/home/features/error/store/error.reducer';
import * as fromExportGasX from '@app/home/features/export-gasx/store/export-gasx.reducer';
import * as fromGasBlock from '@app/home/features/gas-block/store/gas-block.reducer';
import * as fromLaunchSimulation from '@app/home/features/launch-simulation/store/launch-simulation.reducer';
import * as fromReport from '@app/home/features/report/store/report.reducer';
import * as fromSettings from '@app/home/features/settings/store/settings.reducer';
import * as fromTopology from '@app/home/features/topology/store/topology.reducer';
import { CoreActions } from '@core/store';
import * as fromCore from '@core/store/core.reducer';
import { RouterReducerState, routerReducer } from '@ngrx/router-store';
import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';

import { NOOP } from './actions';

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  router: RouterReducerState;
  [fromCore.coreFeatureKey]: fromCore.State;
  [fromTopology.topologyFeatureKey]: fromTopology.State;
  [fromSettings.settingsFeatureKey]: fromSettings.State;
  [fromAnalysis.analysisFeatureKey]: fromAnalysis.State;
  [fromLaunchSimulation.launchSimulationFeatureKey]: fromLaunchSimulation.State;
  [fromExportGasX.exportGasXFeatureKey]: fromExportGasX.State;
  [fromAlarms.alarmsFeatureKey]: fromAlarms.State;
  [fromGasBlock.gasBlockFeatureKey]: fromGasBlock.State;
  [fromDashboard.dashboardFeatureKey]: fromDashboard.State;
  [fromReport.reportFeatureKey]: fromReport.State;
  [fromError.errorFeatureKey]: fromError.State;
}
/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const ROOT_REDUCERS = new InjectionToken<ActionReducerMap<State, Action>>('RootState', {
  factory: (): ActionReducerMap<State, Action> => ({
    router: routerReducer,
    [fromCore.coreFeatureKey]: fromCore.reducer,
    [fromTopology.topologyFeatureKey]: fromTopology.reducer,
    [fromSettings.settingsFeatureKey]: fromSettings.reducer,
    [fromAnalysis.analysisFeatureKey]: fromAnalysis.reducer,
    [fromLaunchSimulation.launchSimulationFeatureKey]: fromLaunchSimulation.reducer,
    [fromExportGasX.exportGasXFeatureKey]: fromExportGasX.reducer,
    [fromAlarms.alarmsFeatureKey]: fromAlarms.reducer,
    [fromGasBlock.gasBlockFeatureKey]: fromGasBlock.reducer,
    [fromDashboard.dashboardFeatureKey]: fromDashboard.reducer,
    [fromReport.reportFeatureKey]: fromReport.reducer,
    [fromError.errorFeatureKey]: fromError.reducer
  })
});

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action) => {
    const result = reducer(state, action);
    if (
      action.type !== NOOP.type &&
      action.type !== CoreActions.showSpinner.type &&
      action.type !== CoreActions.hideSpinner.type &&
      !action.type.startsWith('@ngrx/router-store')
    ) {
      // eslint-disable-next-line no-console
      console.groupCollapsed(action.type);
      // eslint-disable-next-line no-console
      console.log('prev state', state);
      // eslint-disable-next-line no-console
      console.log('action', action);
      // eslint-disable-next-line no-console
      console.log('next state', result);
      // eslint-disable-next-line no-console
      console.groupEnd();
    }

    return result;
  };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
// TODO important : but back logger only for local using this line : environment.env === 'local' ? [logger] : [];
export const metaReducers: MetaReducer<State>[] = [logger];
